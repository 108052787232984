import React, { useEffect } from "react";
import dgPhoto from "../../images/dg.jpg";
import "./About.css";
import AOS from "aos";
import "aos/dist/aos.css";
import photo from "../../images/logo.png";
import { Helmet } from "react-helmet-async";

const AboutMain = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const madateList = [
    {
      id: 9,
      details:
        "To initiate, support and develop Micro, Small and Medium Enterprises (MSME) Policies, Programmes and Activities in Plateau State.",
    },
    {
      id: 1,
      details:
        "Stimulating, monitoring and coordinating the development of the MSMEs eco-system in Plateau State.",
    },
    {
      id: 2,
      details:
        "Initiating and articulating policy ideas for small and medium enterprises growth and development for Plateau State.",
    },
    {
      id: 3,
      details:
        "Serving as vanguard for rural industrialization, poverty reduction, job creation and enhanced livelihoods for Plateau households and MSMEs.",
    },
    {
      id: 4,
      details:
        "Linking MSMEs to internal and external sources of finance, appropriate technology, technical skills as well as to large Corporations.",
    },
    {
      id: 5,
      details:
        "Promoting and providing access to industrial infrastructures such as layouts, incubators, industrial parks among others to MSMEs.",
    },
    {
      id: 6,
      details:
        "Intermediating between MSMEs and Government of Plateau State and the National Government [PLASMIDA is the voice of the MSMEs]",
    },
    {
      id: 7,
      details:
        "Working in partnership with other institutions in both public and private sector to create an enabling environment for businesses in general and MSME activities in particular within Plateau State.",
    },
    {
      id: 8,
      details:
        "Developing, facilitating and promoting programmes, instruments, and support services that are geared towards mitigation and adaptation impact of climate change to enhance MSME operations in Plateau State",
    },
  ];

  const values = [
    {
      id: 1,
      title: "Our Mission",
      details:
        "To develop the capacity of Micro Small and Medium Enterprises in the State towards job creation, and poverty reduction.",
    },
    {
      id: 2,
      title: " Our Vision",
      details:
        " To be a world class Agency that is passionate and efficient in the    promotion and development of Micro Small and Medium Enterprises.",
    },
    {
      id: 3,
      title: "Core Values",
      details: "Team work, Resourcefulness, Integrity, Passion, (T R I P)",
    },
    {
      id: 4,
      title: "Product and Services",
      details: " Coaching, Mentoring, Training, Linkages, Consultancy",
    },
  ];

  return (
    <div className="py-3 px-3">
      <Helmet>
        <title>About Us </title>
        <meta
          name="description"
          content="The Agency is strategically positioned, to assist in the human
              capital development needs of the existing and would be Micro,
              Small and Medium Enterprises (MSME’s)"
        />
        <meta property="og:title" content="About Us" />
        <meta property="og:image" content={photo} />
        <link rel="canonical" type="" href="/about" />
      </Helmet>
      <div className="row">
        <h1 className="text-center py-3" style={{ fontSize: "1.7rem" }}>
          Welcome to PLASMIDA
        </h1>
        <div
          data-aos="zoom-in-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
          className="col-lg-4 col-12 py-3 d-flex justify-content-center align-items-center"
        >
          <div className=" photo-div " style={{ height: "100%" }}>
            <img
              src={dgPhoto}
              className="img-fluid "
              alt="..."
              style={{
                height: "100%",
                objectFit: "cover",
                background: "rgba(48, 46, 46, 0.5)",
              }}
            />
          </div>
        </div>
        <div
          data-aos="zoom-in-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
          className="col-lg-8  col-12 py-3  d-flex justify-content-center align-items-center  "
        >
          <div className=" text-div about-shadow p-3">
            <p className="text-start">
              The commitment and determination of the Governor to move the state
              out of economic stagnation to a commercial hub of the nation, was
              demonstrated through the establishment of the Plateau State
              Microfinance Development Agency(PLASMIDA) in 2016 when the bill
              establishing the Agency was signed into law by the Executive
              Governor of Plateau State.
            </p>
            <p className="text-start">
              The Agency is strategically positioned, to assist in the human
              capital development needs of the existing and would be Micro,
              Small and Medium Enterprises (MSME’s) operators, provide linkage
              services in terms of access to finance, market information,
              quality control services to the MSME’s, source, initiate and
              coordinate bilateral or multilateral technical assistance or
              partnership programmes with local and international organizations
              and foundations.
            </p>
            <p className="text-start">
              Plateau State Microfinance Development Agency is set to put in
              place deliberate platforms to facilitate government intervention
              that would ensure that disadvantaged categories of people in the
              state benefit from any intervention programme that will improve
              household income, attract people-oriented development projects,
              implement and coordinate MSME policies and programmes in the
              State.
            </p>
            <p className="text-start">
              Ensure measurable improvement to the quality of life of the MSME
              in the State by ensuring that business development support
              services necessary to impact positively on the business of the
              poor and micro entrepreneurs are developed through quality
              education and confidence building programmes.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid px-0">
        <div className="row  mt-1 gy-4">
          {values.map((value) => (
            <div key={value.id} className=" col-md-6 px-2">
              <div className="value-wrapper my-3 px-3">
                <h4 className="text-center pt-2">{value.title}</h4>
                <p className="text-center px-"> {value.details} </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="py-5">
        <h3 className="text-center"> Our Mandate</h3>
        <ul className="mandate box-shadow px-0">
          {madateList.map((mandate) => (
            <li key={mandate.id}> {mandate.details}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AboutMain;
