export const programData = [
  {
    id: 1,
    title:
      "Plateau State COVID-19 Action Recovery And Economic Stimulus. (Plateau-CARES)",
    description: {
      title: null,
      desc: "The Nigeria COVID-19 Action Recovery and Economic Stimulus (NG-CARES) Programme is an intervention that is built on the platforms of existing projects of the Federal Government of Nigeria.  These include National Cash Transfer Programme (NCTP), Youth Employment and Social Support Operations (YESSO), Community and Social Development Project (CSDP), FADAMA, Government Enterprise and Empowerment Programme, (GEEP). These projects are operational at the Federal and State level. The NG-CARES Programme is an emergency operation designed to support budgeted Programme of expenditures and interventions at the State level – targeting existing and newly emerging vulnerable and poor households, agricultural value chains, and Micro and Small Enterprises (MSEs) affected by the economic crisis, hence now domesticated and referred to as PLATEAU-CARES in the case of Plateau State.",
      listTitle: null,
      listItems: null,
      photos: null,
      tableHeaders: null,
      tableData: null,
    },

    description2: {
      title: "DURATION / SPAN",
      desc: "The World Bank, through its USD 750 million Nigeria Covid-19 Action Recovery and Economic Stimulus (NG-CARES) Program, seeks to provide State-level Financing through targeted interventions in the form of Subsidized Loans, Business Grants as well as Employment and Job Creation Grants to support State Recovery and Enhance Capabilities of MSMEs over the next 18 to 24 months.",
      tableHeaders: null,
      tableData: null,
      listTitle:
        "The Programme provides financial support to the States to fulfill objectives in three key results areas:",
      listItems: [
        {
          id: "1",
          title:
            "increasing cash transfers and livelihood support to poor and vulnerable households;",
        },
        {
          id: "2",
          title:
            "increasing food security and safe functioning of food supply chains for poor households;",
        },
        {
          id: "3",
          title:
            "preventing collapse and facilitating recovery of household/micro enterprises.",
        },
      ],
      photos: null,
    },
    description3: {
      title: "Programme Development Objective (PDO):",
      desc: "“To expand access to livelihood support and food security services, and grants for poor and vulnerable households and firms”",
      tableHeaders: null,
      tableData: null,
      listTitle:
        "Progress towards achieving the PDO will be measured using three PDO results indicators:",
      listItems: [
        {
          id: "1",
          title:
            "Number of beneficiaries of targeted safety nets and with access to basic social services (disaggregated by gender)",
        },
        {
          id: "2",
          title:
            "Number of farmers supported to increase food production (disaggregated by gender)",
        },
      ],
      photos: null,
    },

    description4: {
      title: "The Key Performance Indicators (KPIs)",
      desc: "The Key Performance Indicators (KPIs) would be developed based on the three (3) Key Result Areas (KRAs), and with reference to each of the interventions in each of the areas of emergencies response and the activities undertaken by the implementing Agencies. The Key Performance Indicators are:",
      tableHeaders: [
        { id: 1, title: "Indicator Name" },
        { id: 2, title: "DLI" },
        { id: 3, title: "Baseline" },
        { id: 4, title: "End Target" },
      ],
      tableTitle:
        "Result Area 1: Increasing social transfers, basic services, & livelihood support to poor and vulnerable households ",
      tableDesc: "",
      tableData: [
        {
          id: "1",
          indicatorName: "1",
          intervention:
            "Planning, promotion, coaching on business orientation and final selection",
          content:
            "Internal planning Promotion of the Start-up Loop, screening of applicants, pre-selection of participants Group coaching (2 sessions) on business orientation Final selection of participants",
          duration: "5-6 weeks",
        },
      ],
      listTitle: "The Key Performance Indicators are",
      listItems: [
        {
          id: "sl1",
          title:
            "Result Area 1: Increasing social transfers, basic services, & livelihood support to poor and vulnerable households",
        },
        {
          id: "sl2",
          title:
            "Result Area 2: Increasing food security and safe functioning of food supply chains for poor households ",
        },
        {
          id: "sl3",
          title:
            "Result Area 3: Facilitating recovery and enhancing capabilities of micro and small enterprises",
        },
      ],
      photos: null,
    },

    description5: {
      title: "",
      desc: "Plateau State Microfinance Development Agency is saddled with the responsibility to implement Result Area 3. With three Disbursement Link Indicators (DLIs)",
      tableHeaders: [
        { id: 1, title: "Disbursement Linked Indicators" },
        { id: 2, title: "State MSE Support Objectives" },
        { id: 3, title: "Proposed CARES PforR Results Area 3" },
      ],
      tableData: [
        {
          id: "1",
          disbursementLinkIndicator: "DLI 3.1 Credit Grant",
          objectives: "Easing Financial Constraints on MSEs",
          proposedResult: [
            {
              id: "1",
              desc: "Grants amounting to finance up to 40 percent of the amount of new loans originated by qualified financial institutions to co-finance qualified beneficiaries in COVID-19 crisis (Performing loans during  COVID-19",
            },
          ],
        },
        {
          id: "2",
          disbursementLinkIndicator: "DLI 3.2 Operations Grant",
          objectives: "Employment and Job Creation Grants ",
          proposedResult: [
            {
              id: "1",
              desc: "Grants to support operational expenditure post COVID-19 to incentivize employment retention",
            },
            {
              id: "2",
              desc: "Operational costs including rent, utilities, private security (for fragile, conflict, and violence [FCV] States) as well as acquiring solar panels to secure sustainable access to energy",
            },
            {
              id: "3",
              desc: "Grants to support operational expenditure post COVID-19 to incentivize employment retention",
            },
          ],
        },
        {
          id: "3",
          disbursementLinkIndicator: "DLI 3.3 IT Enhancement Grant",
          objectives: "Upgrading MSE Capabilities ",
          proposedResult: [
            {
              id: "1",
              desc: "Grants to support adoption and upgrade of digital technology",
            },
            {
              id: "2",
              desc: "Investing in MSEs’ IT infrastructure and enhancing MSEs capability through leveraging digital technology",
            },
          ],
        },
      ],
      listTitle: "The Key Performance Indicators are",
      listItems: [
        {
          id: "sl1",
          title:
            "Result Area 1: Increasing social transfers, basic services, & livelihood support to poor and vulnerable households",
        },
        {
          id: "sl2",
          title:
            "Result Area 2: Increasing food security and safe functioning of food supply chains for poor households ",
        },
        {
          id: "sl3",
          title:
            "Result Area 3: Facilitating recovery and enhancing capabilities of micro and small enterprises",
        },
      ],
      photos: null,
    },

    applicationTitle:
      "Applications are being accepted for any of the Modular Course if interested apply here",
    applicationLink: "null",
    moreInfoTitle: "For more information contact:",
    moreInfoLink: "plasmida@plateaustate.gov.ng",
    photos: null,
    date: null,
  },
  {
    id: 2,
    title: "Create, Start And Scale",
    description: {
      title: null,
      desc: "This is a Modular basic Entrepreneurship Training Programme is because of a partnership between the Plateau State Microfinance Development Agency (PLASMIDA), German Agency for International Cooperation (GIZ), and GOPA Worldwide Consultants. The project targets the MSMEs in the poultry and ICT value chain. They would be taken from the ideation stage of their businesses to the point where they have the capacity to scale their businesses. The three major stages of the project include ‘CREATE’, ‘START’ and ‘SCALE’.",
      listTitle: null,
      listItems: null,
      photos: null,
      tableHeaders: null,
      tableData: null,
    },

    description2: {
      title: null,
      desc: "CREATE is for aspiring entrepreneurs that have decided that entrepreneurship is the best career path for them. They are yet to pin down their aspiration for business to a single idea. Here participants are guided through the venture creation process, as they explore entrepreneurship strategies concretize and validate business ideas. They are guided in validating their ideas, helping them to avoid the dangers of investing time and other resources on the wrong idea.",
      listTitle: null,
      listItems: null,
      photos: null,
      tableHeaders: null,
      tableData: null,
    },

    description3: {
      title: null,
      desc: "START graduates the aspiring entrepreneurs from ideation to having a concrete business plan to execute the idea. They are given the platform to dissect the plan to ascertain the likely things that might go wrong in the marketplace. They are also guided on how these likely challenges can be mitigated. The entrepreneurs are given the opportunity to access how much the business will likely need and if the business will be profitable as planned. A clearly written plan is meant to help the participants successfully pitch their ideas to potential investors. These entrepreneurs will also be guided in their bid to formalize their business through registration with relevant government agencies, opening of bank accounts, and the establishment of the best channel to send their proposed value to the market.",
      tableHeaders: null,
      tableData: null,
      listTitle: null,
      listItems: null,
      photos: null,
    },

    description4: {
      title: null,
      desc: "SCALE is for existing MSMEs interested and ready to expand their market performance and employment potential. It starts with a systematic diagnosis of growth concerns enabling the participants to identify the relevant pivoting measures including Advanced business Management Training; Product development and marketing; Employment creation and personnel management and Coaching for accessing finance and markets. And numbers of organizations are also going through the same experience in Niger and Ogun States. In addition to the strategic support given to the various partner organizations in the three states to enable us effectively carry out this modular training approach, there is a deliberate establishment of an Ecosystem of the major stakeholders in the socio-economic milieu of the three states, with particular emphasis on ICT and Poultry.",
      tableHeaders: null,
      tableData: null,
      listTitle: null,
      listItems: null,
      photos: null,
    },
    description5: {
      title: null,
      desc: null,
      tableHeaders: null,
      tableData: null,
      listTitle: null,
      listItems: null,
      photos: null,
    },

    applicationTitle:
      "Applications are being accepted for any of the Modular Course if interested apply here",
    applicationLink: "null",
    moreInfoTitle: "For more information contact:",
    moreInfoLink: "plasmida@plateaustate.gov.ng",
    photos: null,
    date: null,
  },
  {
    id: 3,
    title: "Entrepreneurship And Financial Literacy Training",
    description: {
      title: null,
      desc: "The Plateau State Microfinance Development Agency (PLASMIDA) is also positioned to deploy Entrepreneurship and Financial Literacy Trainings as part of its Business Development and Support Services with the wide curriculum and varying interactive and adult learning approaches, the Agency has had the opportunity to go into agreement for the deployment of this service to quite a number of organizations both, private, nongovernmental and faith-based institutions to support MSME and Clusters engaged in Income Generating Activities (IGA) some of which include;",
      listTitle: null,
      listItems: [
        { id: "sl1", title: "Norwegian Refugee Council (NRC)" },
        {
          id: "sl2",
          title: "USAIDS",
        },
        { id: "sl3", title: "Safer World Foundation, and" },
        {
          id: "sl4",
          title: "ZIVA Initiative.",
        },
      ],
      photos: null,
      tableHeaders: null,
      tableData: null,
    },

    description2: {
      title: null,
      desc: null,
      listTitle: null,
      listItems: null,
      photos: null,
      tableHeaders: null,
      tableData: null,
    },

    description3: {
      title: null,
      desc: null,
      tableHeaders: null,
      tableData: null,
      listTitle: null,
      listItems: null,
      photos: null,
    },

    description4: {
      title: null,
      desc: null,
      tableHeaders: null,
      tableData: null,
      listTitle: null,
      listItems: null,
      photos: null,
    },
    description5: {
      title: null,
      desc: null,
      tableHeaders: null,
      tableData: null,
      listTitle: null,
      listItems: null,
      photos: null,
    },

    applicationTitle: null,
    applicationLink: null,
    moreInfoTitle: "For more information contact:",
    moreInfoLink: "plasmida@plateaustate.gov.ng",
    photos: null,
    date: null,
  },
  {
    id: 4,
    title: "Agric-Business Small And Medium Enterprises Investment Scheme",
    description: {
      title: null,
      desc: "The desire to provide linkage services specifically with regard to access to finance had led the Agency to undertake and position itself as an Entrepreneurship Development Institute (EDI) certified by the Central Bank of Nigeria (CBN). Designed to expressly address the challenge of rising youth unemployment and its threat to political stability, social cohesion and economic growth and the need to reduce poverty to ensuring the creation of sustainable jobs.",
      listTitle: null,
      listItems: null,
      photos: null,
      tableHeaders: null,
      tableData: null,
    },

    description2: {
      title: null,
      desc: "As an EDI the Agency is positioned to develop entrepreneurship spirit amongst Nigerians by developing the skills of trainees to successfully start, manage, diversify, and expand business enterprise, and facilitate easy access to start-up capital to trainees, especially funds from Banks and allied financial institutions. To mention specifically, trainees are expected to access funding from the NIRSAL Microfinance Bank as a means of access to finance for the start-up or scale up capital.",
      listTitle: null,
      listItems: null,
      photos: null,
      tableHeaders: null,
      tableData: null,
    },

    description3: {
      title: null,
      desc: "So far over one thousand six hundred (1600) trainees have gone through the training with the Plateau State Microfinance Development Agency.",
      tableHeaders: null,
      tableData: null,
      listTitle: null,
      listItems: null,
      photos: null,
    },

    description4: {
      title: null,
      desc: null,
      tableHeaders: null,
      tableData: null,
      listTitle: null,
      listItems: null,
      photos: null,
    },
    description5: {
      title: null,
      desc: null,
      tableHeaders: null,
      tableData: null,
      listTitle: null,
      listItems: null,
      photos: null,
    },
    applicationTitle: null,
    applicationLink: null,
    moreInfoTitle: "For more information contact:",
    moreInfoLink: "plasmida@plateaustate.gov.ng",
    photos: null,
    date: null,
  },
  {
    id: 5,
    title: "Simon Lalong Empowerment Scheme (SLEMS)",
    description: {
      title: null,
      desc: "The Simon Lalong Empowerment Scheme as an initiative coopted in to the programme of the Agency with the aim of empowering youths, women and Start-up with various skill sets across the seventeen Local Government Areas in the State, the skill sets which include financial literacy, record keeping and other aspects for soft skills and Computer repairs and Networking, Phone repairs, Catering and event management, Welding and Fabrication, Aluminum Works, Poultry, Fishery, Tailoring, Beauty and Barbing Salon for Hard Skills was to ensure that the state produces youths who are economically equipped with the right skills to generate income and be self-reliant economically.",
      listTitle: null,
      listItems: null,
      photos: null,
      tableHeaders: null,
      tableData: null,
    },

    description2: {
      title: null,
      desc: "The intervention was targeted at market women associations, traditional and religious bodies, youth organizations and communities across the state. The participants were at the end of the trainings assisted with Starter packs and microgrants to help them with their businesses.",
      listTitle: null,
      listItems: null,
      photos: null,
      tableHeaders: null,
      tableData: null,
    },

    description3: {
      title: null,
      desc: null,
      tableHeaders: null,
      tableData: null,
      listTitle: null,
      listItems: null,
      photos: null,
    },

    description4: {
      title: null,
      desc: null,
      tableHeaders: null,
      tableData: null,
      listTitle: null,
      listItems: null,
      photos: null,
    },
    description5: {
      title: null,
      desc: null,
      tableHeaders: null,
      tableData: null,
      listTitle: null,
      listItems: null,
      photos: null,
    },
    applicationTitle: null,
    applicationLink: null,
    moreInfoTitle: null,
    moreInfoLink: null,
    photos: null,
    date: null,
  },
  {
    id: 6,
    title: "PLASMIDA/GIZ Start-Up Business Training And Coaching Loop",
    description: {
      title: null,
      desc: "GIZ developed the SME Business Training and Coaching Loop (SME Loop) in Sierra Leone in 2014 and has since piloted it in Sierra Leone and Benin with encouraging results. In August 2017, GIZ SEDIN in Nigeria has successfully piloted the SME Loop courses for existing MSMEs and start-ups in three states: Plateau, Ogun and Niger. One lesson learnt was that the SME Loop had to be adapted to the specific needs of start-ups.",
      listTitle: null,
      listItems: null,
      photos: null,
      tableHeaders: null,
      tableData: null,
    },

    description2: {
      title: null,
      desc: "The Start-up Business Training and Coaching Loop (Start-up Loop) is designed to provide guidance, training and coaching in entrepreneurial and managerial competencies, understanding the business environment and business planning for start-ups. The duration of the Start-up Loop is a minimum of 4 months. It combines coaching and training interventions. Its methodology is based on adult learning and experiential learning principles.",
      listTitle: null,
      listItems: null,
      photos: null,
      tableHeaders: null,
      tableData: null,
    },

    description3: {
      title: null,
      desc: "Through the participation in the Start-up Loop, potential entrepreneurs and business start-ups are guided through a process of enhancing entrepreneurial and managerial competencies, as well as preparing a business plan and testing its viability.",
      tableHeaders: null,
      tableData: null,
      listTitle: "The Start-up Loop consists of 7 phases:",
      listItems: [
        { id: "sl1", title: "Phase 1: Planning and selection" },
        {
          id: "sl2",
          title: "Phase 2: Training on Business Management and Planning",
        },
        { id: "sl3", title: "Phase 3: Business planning coaching " },
        {
          id: "sl4",
          title:
            "Phase 4: Training on Personal Entrepreneurial Competencies (PECs)",
        },
        { id: "sl5", title: "Phase 5: Financing and linkages coaching " },
        { id: "sl6", title: "Phase 6: Awarding ceremony" },
        {
          id: "sl7",
          title: "Phase 7: Follow-up coaching sessions and final evaluation",
        },
      ],
      photos: null,
    },

    description4: {
      title: "Plateau State Microfinance Development Agency (PLASMIDA)",
      desc: "A Training of Trainers and Coaches was completed in March 2018, followed by a period of planning and promotion. In July 2018, PLASMIDA pre-selected 63 applicants who underwent Business Orientation coaching sessions. From there, 30 potential entrepreneurs were identified as participants for the pilot Start-up Loop.",
      tableHeaders: [
        { id: 1, title: "Phase" },
        { id: 2, title: "Intervention" },
        { id: 3, title: "Content" },
        { id: 4, title: "Duration" },
      ],
      tableData: [
        {
          id: "1",
          phase: "1",
          intervention:
            "Planning, promotion, coaching on business orientation and final selection",
          content:
            "Internal planning Promotion of the Start-up Loop, screening of applicants, pre-selection of participants Group coaching (2 sessions) on business orientation Final selection of participants",
          duration: "5-6 weeks",
        },
        {
          id: "2",
          phase: "2",
          intervention: "Training 1: Business management and planning",
          content:
            "Assessment of business idea Business planning, covering: marketing plan, production plan, organisation and management plan",
          duration: "3 days",
        },
        {
          id: "3",
          phase: "3",
          intervention: "Business planning coaching",
          content:
            "6 group coaching sessions to cover the first three chapters of the business plan & prepare for training 2",
          duration: "6 weeks",
        },
        {
          id: "4",
          phase: "4",
          intervention:
            "Training on Personal Entrepreneurial Competencies (PECs)",
          content:
            "Development of financial plan Identify PECs and enhancement opportunities, critical success factors for business",
          duration: "3 days",
        },
        {
          id: "5",
          phase: "5",
          intervention: "Financing and linkages coaching",
          content:
            "6 individual coaching sessions per coachee to complete the financial plan and to follow up on critical success factors and competencies",
          duration: "6 weeks",
        },
        {
          id: "6",
          phase: "6",
          intervention: "Pitching event Awarding ceremony",
          content:
            "Business plan presentation and viability test Course completion award",
          duration: "1 day ",
        },
        {
          id: "7",
          phase: "7",
          intervention: "Follow-up coaching sessions and final evaluation",
          content:
            "2 group coaching sessions for follow-up Final evaluation after 12 months since beginning of Start-up Loop",
          duration: "2 weeks",
        },
      ],
      listTitle: null,
      listItems: null,
      photos: null,
    },
    description5: {
      title: null,
      desc: null,
      tableHeaders: null,
      tableData: null,
      listTitle: null,
      listItems: null,
      photos: null,
    },

    applicationTitle: null,
    applicationLink: null,
    moreInfoTitle: null,
    moreInfoLink: null,
    photos: null,
    date: null,
  },
];
