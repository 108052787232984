import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MetaTags from "react-meta-tags";
import photo from "../../images/logo.png";
import { beneficiariesData } from "../../data/beneficiariesData";
import BackButton from "../BackButton/BackButton";

const Beneficiary = () => {
  const params = useParams();
  const [numberOfData, setNumberOfData] = useState(6);

  const [beneficiary, setBeneficiary] = useState(null);
  const [beneficiaryProductPhotos, setBeneficiaryProductPhotos] =
    useState(null);

  const handleSetBeneficiary = () => {
    beneficiariesData.forEach((beneficiary) => {
      if (beneficiary.id === Number(params.id)) {
        setBeneficiary(beneficiary);
        setBeneficiaryProductPhotos(beneficiary.photos.slice(0, numberOfData));
      }
    });
  };

  const handleGetMoreData = () => {
    setNumberOfData(numberOfData + 6);
  };

  useEffect(() => {
    handleSetBeneficiary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfData]);
  return (
    <div>
      <MetaTags>
        <title>{beneficiary ? beneficiary.title : "Beneficiary"}</title>
        <meta name="description" content="Our Beneficiarys" />
        <meta property="og:title" content="Beneficiary" />
        <meta property="og:image" content={photo} />
        <link rel="canonical" type="" href="/beneficiary/" />
      </MetaTags>

      {!beneficiary ? (
        <div
          style={{
            height: "100vh",
          }}
        >
          Loading...
        </div>
      ) : (
        <div key={beneficiary.id} className="beneficiarys mb-4 ">
          <BackButton />
          <div>
            <h3 className="text-center  mb-2 py-2">
              {beneficiary.name && beneficiary.name}'s Products
            </h3>
          </div>

          <section className="gallery ">
            <div id="image-gallery">
              <div className="row p-0 gx-3 gy-3 d-flex align-items-center justify-content-center beneficiarys-div">
                {beneficiaryProductPhotos &&
                  beneficiaryProductPhotos.map((img) => (
                    <>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-12 mb-2 d-flex align-items-center  justify-content-center">
                        <div
                          className="card-container"
                          style={{ width: "80%", height: "100%" }}
                        >
                          <div className="card img-full-width">
                            <div
                              className="img-top"
                              style={{ width: "100%", height: "220px" }}
                            >
                              <img
                                src="https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg"
                                className="card-img-top img-full-width"
                                alt="Profile_pic"
                                style={{ background: "rgba(48, 46, 46, 0.5)" }}
                              />
                            </div>
                            <div className="card-body">
                              <h6 className="card-title">{img.title}</h6>
                              <p className="card-title">&#8358;{img.price}</p>
                              <small className="card-text">
                                Some quick example text to build on the card
                              </small>{" "}
                              <br />
                              <small>
                                Phone -<strong> {beneficiary.phone} </strong>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </section>
          
          {beneficiaryProductPhotos &&
            beneficiaryProductPhotos.length !== beneficiary.photos.length && (
              <div className="d-flex justify-content-center mb-5">
                <button
                  type="click"
                  onClick={handleGetMoreData}
                  className="btn btn-success"
                >
                  {" "}
                  Load More
                </button>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default Beneficiary;
