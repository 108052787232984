import React from "react";

const NotFound = () => {
  return (
    <div style={{ height: "40vh" }}>
      <h1 className="d-flex justify-content-center align-item-center pt-5">
        Page Not Found
      </h1>
    </div>
  );
};

export default NotFound;
