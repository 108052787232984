import React, { useEffect, useState } from "react";
import "./Gallery.css";
import "./beneficiaries.css";
import AOS from "aos";
import "aos/dist/aos.css";
import MetaTags from "react-meta-tags";
import photo from "../../images/logo.png";
import { beneficiariesData } from "../../data/beneficiariesData";
import { Link } from "react-router-dom";

const Beneficiaries = () => {
  const [numberOfData, setNumberOfData] = useState(6);
  const [displayData, setDisplayData] = useState([]);

  const handleGetMoreData = () => {
    setNumberOfData(numberOfData + 6);
  };

  const handleSetDisplayData = () => {
    setDisplayData(beneficiariesData.slice(0, numberOfData));
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    handleSetDisplayData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfData]);

  return (
    <div className="gallery-container px-2">
      <MetaTags>
        <title>Beneficiaries</title>
        <meta name="description" content="Our Beneficiaries" />
        <meta property="og:title" content="Beneficiaries" />
        <meta property="og:image" content={photo} />
      </MetaTags>

      <section className="gallery">
        <div id="image-gallery">
          <div className="row p-0 gy-5  justify-content-center">
            {displayData.length === 0 ? (
              <div
                className="text-center"
                style={{
                  height: "100vh",
                }}
              >
                Loading...
              </div>
            ) : (
              displayData
                .filter((item) => item.photos.length > 0)
                .map((item) => (
                  <div key={item.id} className="col-lg-6 col-12">
                    <div className="row p-0 gx-3 gy-3 box-shadow">
                      <div className="col-lg-5 col-md-5 col-12">
                        <div
                          className="card-container"
                          style={{ width: "100%", height: "100%" }}
                        >
                          <div
                            className="card"
                            style={{ width: "100%", height: "100%" }}
                          >
                            <div className="img-top">
                              <img
                                src="https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg"
                                className="card-img-top"
                                alt="Profile_pic"
                                style={{ background: "rgba(48, 46, 46, 0.5)" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-7 col-md-6 col-12 ">
                        <div className="row p-0 gx-3 gy-3">
                          <h5 className="text-center">{item.name}</h5>
                          <small className="">
                            Lorem Ipsum DeprecanoLorem Ipsum Deprecano Lorem
                            Ipsum DeprecanoLorem Ipsum Deprecano Lorem Ipsum
                            Ipsum DeprecanoLorem Ipsum Deprecano Lorem Ipsum
                            Deprecano
                          </small>
                          {/* {item.photos.length > 0 &&
                            item.photos.slice(0, 1).map((img) => (
                              <div
                                key={img.id}
                                className="col-md-6  column-height col-12"
                              >
                                <div className="modal-img ">
                                  <div
                                    className="img-wrapper "
                                    data-bs-toggle="modal"
                                    data-bs-target={`#modal` + img.id}
                                  >
                                    <img
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        background: "rgba(48, 46, 46, 0.5)",
                                      }}
                                      src={img.src}
                                      className="img-responsive img-fluid "
                                      alt="..."
                                    />
                                    <div
                                      className="card"
                                      style={{ width: "100%", height: "100%" }}
                                    >
                                      <div className="img-top img-contain">
                                        <img
                                          src="https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg"
                                          className="card-img-top"
                                          alt="Profile_pic"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {handleModal(img)}
                              </div>
                            ))}
                          {item.photos.slice(5).length > 0 && (
                            <div className="col-md-6 column-height col-12">
                              <div className="justify-content-center">
                                <div
                                  className="img-wrapper  justify-content-center "
                                  data-bs-toggle="modal"
                                  data-bs-target={`#moreModal` + item.id}
                                  style={{
                                    background: "rgba(48, 46, 46, 0.5)",
                                    backgroundImage: `url(${item.photos[5].src})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    cursor: "pointer",
                                    height: "120px",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "30px",
                                      color: "white",
                                      backdropFilter: "brightness(30%)",
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <p
                                      className="text-center"
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      +{item.photos.slice(5).length}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {handleMoreModal(item)}
                            </div>
                          )} */}

                          <div className="readmore-btn">
                            <h4 className="text-center more">
                              <Link to={`${item.id}`}> View Products </Link>{" "}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </section>
      {displayData.length > 0 &&
        displayData.length !== beneficiariesData.length && (
          <div className="d-flex justify-content-center mb-5">
            <button
              type="click"
              onClick={handleGetMoreData}
              className="btn btn-success"
            >
              {" "}
              Load More
            </button>
          </div>
        )}
    </div>
  );
};

export default Beneficiaries;
