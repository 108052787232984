import React, { useEffect } from "react";
import "./Contact.css";
import AOS from "aos";
import "aos/dist/aos.css";
import photo from "../../images/logo.png";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="contact-container d-flex justify-content-center align-items-center">
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Find out more about us" />
        <meta property="og:title" content="Contact Us" />
        <meta property="og:image" content={photo} />
        <link rel="canonical" type="" href="/contact" />
      </Helmet>

      <div className="contact-section ">
        <div className=" row contact-card-wrapper box-shadow my-5 justify-content-center align-items-center">
          <div class="col-md-6">
            <div
              data-aos="fade-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              className="contact-card"
            >
              <p>
                <i className="fas fa-map-marker-alt"></i>
                <strong> HEAD OFFICE</strong>
              </p>
              <p class=""> Plot 780, Liberty Boulevard, Jos, Plateau State</p>
            </div>
            <div
              data-aos="fade-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              className="contact-card"
            >
              <p>
                <i className="fas fa-phone"></i> <strong> PHONE</strong>
              </p>
              <p>+2349077109179 | +2349167825539</p>
            </div>
          </div>

          <div class="col-md-6">
            <div
              data-aos="fade-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              className="contact-card"
            >
              <p>
                <i className="fas fa-envelope"></i>
                <strong> EMAIL</strong>
              </p>
              <p>plasmida@plateaustate.gov.ng</p>
            </div>
            <div
              data-aos="fade-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              className="contact-card"
            >
              <p class="">
                {" "}
                <i className="fas fa-clock"></i> <strong> WORKING HOURS</strong>{" "}
              </p>
              <p>
                {" "}
                <strong> Mon - Fri</strong> 8:00 AM to 4:00 PM
              </p>
            </div>
          </div>
        </div>
        {/* <div className="contact-form ">
          <form
            data-aos="fade-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            className="contact"
            // action=""
            // method="post"
          >
            <div
              data-aos="fade-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              className="inputs "
            >
              <input
                type="text"
                name="name"
                className="text-box"
                placeholder="Your Name"
                required
              />
              <input
                type="text"
                name="name"
                className="text-box "
                placeholder="Your email"
                required
              />
            </div>
            <div
              className="text-area"
              data-aos="fade-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <textarea
                type="text"
                name="name"
                className="text-box text-area"
                placeholder="Your Message"
                required
              ></textarea>
            </div>
            <div>
              <button type="submit" name="submit" className="send-btn">
                Send
              </button>
            </div>
          </form>
        </div> */}
      </div>
    </div>
  );
};

export default Contact;
