import React from "react";
import "./Kenburns.css";
import plasmida from "../../images/banners/plasmida.png";
import mandate from "../../images/banners/mandate.png";
import vission from "../../images/banners/vission.png";
import women from "../../images/banners/women.png";
import award1 from "../../images/banners/award1.png";
import award2 from "../../images/banners/award2.png";

import { Carousel } from "react-responsive-carousel";
import News from "../News/News";

const Kenburns = () => {
  const banners = [
    { id: 2, src: vission },
    { id: 4, src: award1 },
    { id: 5, src: award2 },
    { id: 6, src: women },
    { id: 1, src: plasmida },
    { id: 3, src: mandate },
  ];
  return (
    <div className="row mt-3">
      <div className="col-lg-8 col-md-6 col-12 ">
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          interval={5000}
          showThumbs={false}
          showStatus={false}
          transitionTime={2000}
          swipeScrollTolerance={20}
        >
          {banners.map((image) => (
            <div key={image.id}>
              <img src={image.src} alt="banner" />
            </div>
          ))}
        </Carousel>
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        <News />
      </div>
    </div>
  );
};

export default Kenburns;
