import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import MenuList from "../MenuList";
import logo from "../../images/plasmida-logo.png";
import "./navbar.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Affix } from "antd";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [top, setTop] = useState(0.1);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const handleToggle = () => {
    setToggle(!toggle);
    console.log(setTop);
  };
  const menu = MenuList.map((list) => {
    return (
      <li
        key={list.id}
        data-aos="zoom-in-right"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
      >
        <NavLink
          exact="true"
          to={list.url}
          activeclassname="active"
          onClick={() => setToggle(false)}
        >
          {" "}
          {list.title}{" "}
        </NavLink>
      </li>
    );
  });
  return (
    <>
      <Affix offsetTop={top}>
        <div>
          <div className="d-flex nav-list d-none d-xxl-flex d-lg-flex d-xl-flex justify-content-between  flex-wrap">
            <div
              data-aos="zoom-in-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              {" "}
              <i className="fas fa-map-marker-alt"></i> Plot 760, Liberty
              Boulevard, Jos, Plateau State
            </div>
            <div
              data-aos="zoom-in-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              {" "}
              <i className="fas fa-envelope"> </i> plasmida@plateaustate.gov.ng
            </div>
            <div
              data-aos="zoom-in-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              {" "}
              <i className="fas fa-phone"></i> +2349077109179 | +2349167825539
            </div>
          </div>
          <nav className="nav-div">
            <div className="logo-container" style={{ height: "100%" }}>
              <div
                data-aos="zoom-in-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
                className="logo-div"
              >
                <NavLink to="/">
                  <img className="logo" src={logo} alt="plasmida" />{" "}
                  <font className="logo-label">PLASMIDA</font>
                </NavLink>
              </div>
              <div className="menu-icon" onClick={handleToggle}>
                <i className={toggle ? "fas fa-times" : "fas fa-bars"}></i>
              </div>
            </div>

            <ul
              className={
                !toggle ? "menu-list menu-list-dark" : "menu-list close"
              }
            >
              {menu}
            </ul>
          </nav>
        </div>
      </Affix>
    </>
  );
};

export default Navbar;
