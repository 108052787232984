import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { blogData } from "../../data/blogData";
import photo from "../../images/logo.png";
import { Helmet } from "react-helmet-async";
import BackButton from "../BackButton/BackButton";

const BlogPost = () => {
  const params = useParams();
  const [program, setProgram] = useState(null);

  const handleSetProgram = () => {
    blogData.forEach((pr) => {
      pr.id === Number(params.id) && setProgram(pr);
    });
  };

  const handleModal = (img) => (
    <div
      className="modal fade"
      id={"modal" + img.id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img src={img.src} className="img-responsive img-fluid" alt="..." />
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    handleSetProgram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="programs-container">
      <Helmet>
        <title>{program ? program.title : "Blog"}</title>
        <meta name="description" content="Our Blog Posts" />
        <meta property="og:title" content="Blog" />
        <meta property="og:image" content={photo} />
        <link rel="canonical" type="" href="/blog/" />
      </Helmet>

      {!program ? (
        <div
          className="text-center"
          style={{
            height: "100vh",
          }}
        >
          Loading...
        </div>
      ) : (
        <div>
          <BackButton />
          <div key={program.id} className="programs mb-4">
            <div>
              <h3 className="text-center mb-2 py-2">{program.title}</h3>
              <div className="mb-3">
                <p>{program.description && program.description.desc}</p>
                {program.description &&
                  program.description.listItems &&
                  program.description.listItems.map((item) => (
                    <ul className="px-5" key={item.id}>
                      <li> {item.desc} </li>
                      {item.subItems &&
                        item.subItems.map((subItem) => (
                          <li className="px-4" key={subItem.id}>
                            {" "}
                            {subItem.desc}{" "}
                          </li>
                        ))}
                    </ul>
                  ))}
              </div>
              <div className="mb-3">
                <p>{program.description2 && program.description2.desc}</p>
                {program.description2 &&
                  program.description2.listItems &&
                  program.description2.listItems.map((item) => (
                    <ul className="px-5" key={item.id}>
                      <li> {item.desc} </li>
                      {item.subItems &&
                        item.subItems.map((subItem) => (
                          <li className="px-4" key={subItem.id}>
                            {" "}
                            {subItem.desc}{" "}
                          </li>
                        ))}
                    </ul>
                  ))}
              </div>
              <div className="mb-3">
                <p>{program.description3 && program.description3.desc}</p>
                {program.description3 &&
                  program.description3.listItems &&
                  program.description3.listItems.map((item) => (
                    <ul className="px-5" key={item.id}>
                      <li> {item.desc} </li>
                      {item.subItems &&
                        item.subItems.map((subItem) => (
                          <li className="px-4" key={subItem.id}>
                            {" "}
                            {subItem.desc}{" "}
                          </li>
                        ))}
                    </ul>
                  ))}
              </div>
              <div className="mb-3">
                <p>{program.description4 && program.description4.desc}</p>
                {program.description4 &&
                  program.description4.listItems &&
                  program.description4.listItems.map((item) => (
                    <ul className="px-5" key={item.id}>
                      <li> {item.desc} </li>
                      {item.subItems &&
                        item.subItems.map((subItem) => (
                          <li className="px-4" key={subItem.id}>
                            {" "}
                            {subItem.desc}{" "}
                          </li>
                        ))}
                    </ul>
                  ))}
              </div>

              <div className="mb-3">
                <p>{program.description5 && program.description5.desc}</p>
                {program.description5 &&
                  program.description5.listItems &&
                  program.description5.listItems.map((item) => (
                    <ul className="px-5" key={item.id}>
                      <li> {item.desc} </li>
                      {item.subItems &&
                        item.subItems.map((subItem) => (
                          <li className="px-4" key={subItem.id}>
                            {" "}
                            {subItem.desc}{" "}
                          </li>
                        ))}
                    </ul>
                  ))}
              </div>

              <div className="mb-3">
                <p>{program.description6 && program.description6.desc}</p>
                {program.description6 &&
                  program.description6.listItems &&
                  program.description6.listItems.map((item) => (
                    <ul className="px-5" key={item.id}>
                      <li> {item.desc} </li>
                      {item.subItems &&
                        item.subItems.map((subItem) => (
                          <li className="px-4" key={subItem.id}>
                            {" "}
                            {subItem.desc}{" "}
                          </li>
                        ))}
                    </ul>
                  ))}
              </div>
            </div>

            <section className="gallery">
              <h4 className="text-center program-title">Story in pictures</h4>
              <div id="image-gallery">
                <div className="row justify-content-center gy-3 gx-3 programs-div box-shadow">
                  {program.photos.map((img) => (
                    <div key={img.id} className="col-md-3  column-height col-6">
                      <div className="modal-img">
                        <div
                          className="img-wrapper "
                          data-bs-toggle="modal"
                          data-bs-target={`#modal` + img.id}
                        >
                          <img
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                              background: "rgba(48, 46, 46, 0.5)",
                            }}
                            src={img.src}
                            className="img-responsive img-fluid "
                            alt="..."
                          />
                        </div>
                      </div>
                      {handleModal(img)}
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogPost;
