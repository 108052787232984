import retreat1 from "../images/blog/retreat/rtr1.jpg";
import retreat2 from "../images/blog/retreat/rtr2.jpg";
import retreat3 from "../images/blog/retreat/rtr3.jpg";
import retreat4 from "../images/blog/retreat/rtr4.jpg";
import retreat5 from "../images/blog/retreat/rtr5.jpg";

export const beneficiariesData = [
  {
    id: 1,
    name: "Joseph Pam",
    price: "5000",
    description: "Product description",
    phone: "08032134121",
    photos: [
      { id: "retreat1", title: "Product Name", price: "5000", src: retreat1 },
      { id: "retreat2", title: "Product Name", price: "5000", src: retreat2 },
      { id: "retreat3", title: "Product Name", price: "5000", src: retreat3 },
      { id: "retreat4", title: "Product Name", price: "5000", src: retreat4 },
      { id: "retreat5", title: "Product Name", price: "5000", src: retreat5 },
    ],
    date: "6th - 8th September, 2022",
  },
  {
    id: 2,
    name: "Blessing Rwang",
    price: "5000",
    description: "Product description",
    phone: "08032134121",
    photos: [
      { id: "retreat1", title: "Product Name", price: "5000", src: retreat1 },
      { id: "retreat2", title: "Product Name", price: "5000", src: retreat2 },
      { id: "retreat3", title: "Product Name", price: "5000", src: retreat3 },
      { id: "retreat4", title: "Product Name", price: "5000", src: retreat4 },
      { id: "retreat5", title: "Product Name", price: "5000", src: retreat5 },
    ],
    date: "6th - 8th September, 2022",
  },
  {
    id: 3,
    name: "Stephen Pam",
    price: "5000",
    description: "Product description",
    phone: "08032134121",
    photos: [
      { id: "retreat1", title: "Product Name", price: "5000", src: retreat1 },
      { id: "retreat2", title: "Product Name", price: "5000", src: retreat2 },
      { id: "retreat3", title: "Product Name", price: "5000", src: retreat3 },
      { id: "retreat4", title: "Product Name", price: "5000", src: retreat4 },
      { id: "retreat5", title: "Product Name", price: "5000", src: retreat5 },
    ],
    date: "6th - 8th September, 2022",
  },
  {
    id: 4,
    name: "Joyce Dayil",
    price: "5000",
    description: "Product description",
    phone: "08032134121",
    photos: [
      { id: "retreat1", title: "Product Name", price: "5000", src: retreat1 },
      { id: "retreat2", title: "Product Name", price: "5000", src: retreat2 },
      { id: "retreat3", title: "Product Name", price: "5000", src: retreat3 },
      { id: "retreat4", title: "Product Name", price: "5000", src: retreat4 },
      { id: "retreat5", title: "Product Name", price: "5000", src: retreat5 },
    ],
    date: "6th - 8th September, 2022",
  },

  {
    id: 5,
    name: "Joseph Pam",
    price: "5000",
    description: "Product description",
    phone: "08032134121",
    photos: [
      { id: "retreat1", title: "Product Name", price: "5000", src: retreat1 },
      { id: "retreat2", title: "Product Name", price: "5000", src: retreat2 },
      { id: "retreat3", title: "Product Name", price: "5000", src: retreat3 },
      { id: "retreat4", title: "Product Name", price: "5000", src: retreat4 },
      { id: "retreat5", title: "Product Name", price: "5000", src: retreat5 },
    ],
    date: "6th - 8th September, 2022",
  },
  {
    id: 6,
    name: "Victor Rwang",
    price: "5000",
    description: "Product description",
    phone: "08032134121",
    photos: [
      { id: "retreat1", title: "Product Name", price: "5000", src: retreat1 },
      { id: "retreat2", title: "Product Name", price: "5000", src: retreat2 },
      { id: "retreat3", title: "Product Name", price: "5000", src: retreat3 },
      { id: "retreat4", title: "Product Name", price: "5000", src: retreat4 },
      { id: "retreat5", title: "Product Name", price: "5000", src: retreat5 },
    ],
    date: "6th - 8th September, 2022",
  },
  {
    id: 8,
    name: "Stephen Dusu",
    price: "5000",
    description: "Product description",
    phone: "08032134121",
    photos: [
      { id: "retreat1", title: "Product Name", price: "5000", src: retreat1 },
      { id: "retreat2", title: "Product Name", price: "5000", src: retreat2 },
      { id: "retreat3", title: "Product Name", price: "5000", src: retreat3 },
      { id: "retreat4", title: "Product Name", price: "5000", src: retreat4 },
      { id: "retreat5", title: "Product Name", price: "5000", src: retreat5 },
      { id: "retreat1", title: "Product Name", price: "5000", src: retreat1 },
      { id: "retreat6", title: "Product Name", price: "5000", src: retreat2 },
      { id: "retreat7", title: "Product Name", price: "5000", src: retreat3 },
      { id: "retreat8", title: "Product Name", price: "5000", src: retreat4 },
      { id: "retreat9", title: "Product Name", price: "5000", src: retreat5 },
    ],
    date: "6th - 8th September, 2022",
  },
  {
    id: 7,
    name: "Dayil Joseph",
    price: "5000",
    description: "Product description",
    phone: "08032134121",
    photos: [
      { id: "retreat1", title: "Product Name", price: "5000", src: retreat1 },
      { id: "retreat2", title: "Product Name", price: "5000", src: retreat2 },
      { id: "retreat3", title: "Product Name", price: "5000", src: retreat3 },
      { id: "retreat4", title: "Product Name", price: "5000", src: retreat4 },
      { id: "retreat5", title: "Product Name", price: "5000", src: retreat5 },
    ],
    date: "6th - 8th September, 2022",
  },
];
