import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div className="mt-3 px-4">
      {" "}
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="btn btn-success"
      >
        back
      </button>{" "}
    </div>
  );
};
export default BackButton;
