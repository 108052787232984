import React, { useEffect } from "react";
import About from "../../pages/About/About";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Home.css";
import Kenburns from "../../components/kenBurns/Kenburns";
import Target from "../../components/Targets/Target";
import Offering from "../../components/Offering/Offering";
import Partners from "../../components/Partners/Partners";
import photo from "../../images/logo.png";
import { Helmet } from "react-helmet-async";

const Home = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="px-2">
      <Helmet>
        <title>Plateau State Microfinance Development Agency - PLASMIDA</title>
        <meta
          name="description"
          content="The commitment and determination of the Governor to move the state
          out of economic stagnation to a commercial hub of the nation, was
          demonstrated through the establishment"
        />
        <meta property="og:title" content="Home" />
        <meta property="og:image" content={photo} />
        <link rel="canonical" type="" href="/" />
      </Helmet>

      <Kenburns />

      <About />

      <Target />
      <Offering />
      <Partners />
    </div>
  );
};

export default Home;
